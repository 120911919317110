(function( $ ) {

    $.fn.mobileCatalogMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var openLink = jqContainer.find('>a').first();
            if (openLink.length <= 0) {
                console.error('mobileCatalogMenu: open link not found by selector ">a" in', this);
                return false;
            }
            openLink.click(function(e) {
                if (jqContainer.hasClass('open')) {
                    return true;
                } 
                e.preventDefault();
                jqAllAffectedContainers.each(function() {
                    var jqLocalContainer = $(this);
                    jqLocalContainer.removeClass('open');
                })
                jqContainer.addClass('open');
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.mobile-catalog-menu-nav li.has-sub-menu').mobileCatalogMenu();
});
