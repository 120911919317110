(function( $ ) {

    $.fn.subMenuSideMenu = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContaainer = $(this);
            var sideMenuElements = jqContaainer.find('.has-sub-menu');
            sideMenuElements.each(function() {
                var jqSideMenuElement = $(this);
                var openLink = jqSideMenuElement.find('>a').first();
                openLink.hover(function() {
                    var allMenuElemets = sideMenuElements;
                    allMenuElemets.each(function() {
                        var eachMenuElement = $(this);
                        eachMenuElement.removeClass('open');
                    })
                    jqSideMenuElement.addClass('open');
                })
            })
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.header-catalog-menu .menu-list').subMenuSideMenu();
});
