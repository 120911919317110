(function( $ ) {

    $.fn.quickSearch = function() {

        var jqAllAffectedContainers = this;

        jqAllAffectedContainers.each(function() {
            var jqContainer = $(this);
            var open = jqContainer.find('a.open').first();
            var close = jqContainer.find('a.close').first();
            if (open.lenght <= 0) {
                console.error('quickSearch: no open link found by selector "a.open" in ', this);
                return fasle;
            }
            if (close.lenght <= 0) {
                console.error('quickSearch: no close link found by selector "a.close" in ', this);
                return fasle;
            }
            open.click(function(e) {
                e.preventDefault();
                if (!jqContainer.hasClass('open')) {
                    jqContainer.addClass('open');
                }
            });
            close.click(function(e) {
                e.preventDefault();
                if (jqContainer.hasClass('open')) {
                    jqContainer.removeClass('open');
                }
            });
        });

        return this;
    };

}( jQuery ));

document.addEventListener('DOMContentLoaded', function() {
    $('.quick-search').quickSearch();
});
