function detailTabs(tabNode) {
    // elements
    this.baseNode = tabNode;
    this.tabsControlls = this.baseNode.getElementsByClassName('tab-selector')[0];
    if (this.tabsControlls === undefined || !this.tabsControlls) {
        console.log('detailTabs: no tabsControlls found by class "tab-selector"');
        return false;
    }
    this.tabsLinks = this.tabsControlls.getElementsByTagName('a');
    this.tabsContentBlock = this.baseNode.getElementsByClassName('tabs')[0];
    if (this.tabsContentBlock === undefined || !this.tabsContentBlock) {
        console.log('detailTabs: no tabsContentBlock found by class "tabs"');
        return false;
    }
    this.tabsContent = this.tabsContentBlock.children;
    // functions
    this.unSelectAll = function() {
        for(let i = 0; i < this.tabsLinks.length; i++) {
           let tabsLinkNode = this.tabsLinks.item(i);
           $(tabsLinkNode).removeClass('current');
        }
        for(let i = 0; i < this.tabsContent.length; i++) {
           let tabContentNode = this.tabsContent.item(i);
           $(tabContentNode).removeClass('current');
        }
        for(let i = 0; i < this.tabsLinks.length; i++) {
           let tabsLinkNode = this.tabsLinks.item(i);
           let tabsContentId = tabsLinkNode.getAttribute("href");
           tabsContentId = tabsContentId.substring(1); // remove #
           $(this.tabsControlls).removeClass(tabsContentId);
        }
    }
    this.selectTab = function(tabsLinkNode) {
        $(tabsLinkNode).addClass('current');
        let tabsContentId = tabsLinkNode.getAttribute("href");
        tabsContentId = tabsContentId.substring(1); // remove #
        const tabContentNode = document.getElementById(tabsContentId);
        $(tabContentNode).addClass('animated');
        $(this.tabsControlls).addClass(tabsContentId);
        setTimeout(function() {
            $(tabContentNode).removeClass('animated');
            $(tabContentNode).addClass('current');
        }, 100);
    }
    // events
    for(let i = 0; i < this.tabsLinks.length; i++) {
       let tabsLinkNode = this.tabsLinks.item(i);
       tabsLinkNode.addEventListener('click', function(e) {
           e.preventDefault();
           this.unSelectAll();
           this.selectTab(tabsLinkNode);
       }.bind(this));
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const customTabsContainers = document.getElementsByClassName('custom-tabs');
    for(let i = 0; i < customTabsContainers.length; i++) {
       let customTabsContainer = customTabsContainers.item(i);
       const detailTabsObj = new detailTabs(customTabsContainer);
    }
});
